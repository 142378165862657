// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-lang-redirect-tsx": () => import("./../../../src/components/lang-redirect.tsx" /* webpackChunkName: "component---src-components-lang-redirect-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-esg-tsx": () => import("./../../../src/pages/esg.tsx" /* webpackChunkName: "component---src-pages-esg-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jobs-tsx": () => import("./../../../src/pages/jobs.tsx" /* webpackChunkName: "component---src-pages-jobs-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */)
}

