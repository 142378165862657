import { Breakpoint, DefaultTheme } from 'styled-components'

const breakpoints: DefaultTheme['breakpoints'] = {
  mobile: '30rem',
  tablet: '48rem',
  tablet_w: '62rem',
  laptop: '75rem',
  desktop: '90rem',
}

export const theme: DefaultTheme = {
  typography: {
    montserrat: "'Montserrat', sans-serif",
    raleway: "'Raleway', sans-serif",
  },
  colors: {
    brand: '#1A6B93',
    accent: '#A8DEFD',
    text: '#55585C',
    light: '#A7A9AC',
    white: '#FFFFFF',
    success: '#4BB543',
    error: '#ff4d70',
  },
  spacing: {
    maxWidth: '75rem',
    headerHeight: '5rem',
    containerPadding: '2.625rem',
  },
  breakpoints,
  media: {
    min: (bp: Breakpoint) =>
      `@media screen and (min-width: ${breakpoints[bp]})`,
    max: (bp: Breakpoint) =>
      `@media screen and (max-width: ${breakpoints[bp]})`,
  },
}

export const hexToRGBA = (hexCode: string, opacity: number): string => {
  let hex = hexCode.replace('#', '')

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
  }

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}
