import { createGlobalStyle, css } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  ${({ theme }) => css`
    /* 
  html5doctor.com Reset Stylesheet v1.6.1
  Last Updated: 2010-09-17
  Author: Richard Clark - http://richclarkdesign.com 
  */
    html,
    body,
    div,
    span,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    abbr,
    address,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    samp,
    small,
    strong,
    sub,
    sup,
    var,
    b,
    i,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    menu,
    nav,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
      font-size: 100%;
      vertical-align: baseline;
      background: transparent;
    }
    body {
      line-height: 1;
    }
    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    menu,
    nav,
    section {
      display: block;
    }
    nav ul {
      list-style: none;
    }
    blockquote,
    q {
      quotes: none;
    }
    blockquote:before,
    blockquote:after,
    q:before,
    q:after {
      content: '';
      content: none;
    }
    a {
      margin: 0;
      padding: 0;
      font-size: 100%;
      vertical-align: baseline;
      background: transparent;
    }
    /* change colours to suit your needs */
    ins {
      background-color: #ff9;
      color: #000;
      text-decoration: none;
    }
    /* change colours to suit your needs */
    mark {
      background-color: #ff9;
      color: #000;
      font-style: italic;
      font-weight: bold;
    }
    del {
      text-decoration: line-through;
    }
    abbr[title],
    dfn[title] {
      border-bottom: 1px dotted;
      cursor: help;
    }
    table {
      border-collapse: collapse;
      border-spacing: 0;
    }
    /* change border colour to suit your needs */
    hr {
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid #cccccc;
      margin: 1em 0;
      padding: 0;
    }
    input,
    select {
      vertical-align: middle;
    }

    html {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      overflow-x: hidden;
      font-family: ${theme.typography.montserrat};
      scroll-behavior: smooth;
      color: ${theme.colors.text};

      @media screen and (max-width: 399px) {
        font-size: 14px;
      }
    }

    main {
      position: relative;
      z-index: 1;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: ${theme.typography.montserrat};
      margin-bottom: 0.5em;
    }

    h1 {
      font-size: 2rem;
    }

    svg path {
      transition: 0.2s;
    }

    ul {
      list-style-type: none;
    }

    a {
      color: inherit;
      transition: 0.2s;

      &.button {
        text-decoration: none;
        text-transform: uppercase;
      }
    }

    p,
    li {
      font-size: 1.125rem;
      line-height: 1.5;
    }

    li,
    p:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  `}
`
